import { Helmet } from "react-helmet-async";
import PagesHistory from "../Shared/MiniComponents/PagesHistory/PagesHistory";
import s from "./Contact.module.scss";
import ContactCardInfo from "./ContactCardSection/ContactCardInfo";
import ContactForm from "./ContactForm/ContactForm";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <div className="container">
        <main className={s.contactPage}>
          <PagesHistory history={["/", "Contact"]} />

          <div className={s.contactContent} id="contact-page">
            <ContactCardInfo />
            <ContactForm />
          </div>

          <div className="map">
            <div>
              <iframe
                title="Google Map"
                allowFullScreen
                height="450"
                loading="lazy"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3719.7105533064073!2d105.74481937526149!3d21.20365428048983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDEyJzEzLjIiTiAxMDXCsDQ0JzUwLjYiRQ!5e0!3m2!1sen!2s!4v1705730055518!5m2!1sen!2s"
                style={{ border: 0, width: "100%" }}
              ></iframe>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
export default Contact;
