import { Link } from "react-router-dom";
import CopyRightsText from "./CopyRightsText/CopyRightsText";
import CustomEmailInput from "./CustomEmailInput/CustomEmailInput";
import s from "./Footer.module.scss";
import DownloadAppInfo from "./WebsiteSocialMedia/DownloadAppInfo";

const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className="container">
        <section className={s.sections}>
          <section className={s.section1}>
            <b>
              <Link to="/">MASTER GLOBAL</Link>
            </b>
            <span>SUBSCRIBE</span>
            <p>Get the latest updates</p>

            <CustomEmailInput />
          </section>

          {/*<section className={s.section2}>
            <b>SUPPORT</b>

            <ul>
              <li>Expanded Quang Minh Industrial Park, Chi Dong Town, Me Linh District, Ha Noi, Vietnam</li>
              <li>
                <a href="mailto:info@mastercomponents.com">info@mastercomponents.com</a>
              </li>
              <li>
                <a href="tel:+88015-88888-9999">(+84) 899 462 678</a>
              </li>
            </ul>
          </section>*/}

          <section className={s.section3}>
            <b>ACCOUNT</b>

            <ul>
              <li>
                <Link to="/profile">MY ACCOUNT</Link>
              </li>
              <li>
                <Link to="/signup">LOGIN / REGISTER</Link>
              </li>
              <li>
                <Link to="/cart">CART</Link>
              </li>
              <li>
                <Link to="/wishlist">WISHLIST</Link>
              </li>
              <li>
                <Link to="/products">SHOP</Link>
              </li>
            </ul>
          </section>

          <section className={s.section4}>
            <b>QUICK LINK</b>

            <ul>
              <li>
                <Link to="/privacy">PRIVACY POLICY</Link>
              </li>
              <li>
                <Link to="/terms">TERMS OF USE</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </section>

          <section className={s.section5}>
            <DownloadAppInfo />
          </section>
        </section>
      </div>
      
    </footer>
  );
};
export default Footer;
