import { appStore, googlePlay, qrCode } from "src/Assets/Images/Images";
import SvgIcon from "../../Shared/MiniComponents/SvgIcon";
import ToolTip from "../../Shared/MiniComponents/ToolTip";
import s from "./DownloadAppInfo.module.scss";

const DownloadAppInfo = () => {
  return (
    <>
      <b>CONTACT US</b>
      <li>Tel: <a href="tel:+84899462678">(+84) 899 462 678</a></li>
      <li>Email: <a href="mailto:info@mastercomponents.com">info@mastercomponents.com</a></li>
      {/*
      <p>Save $3 with App New User Only</p>

      <div className={s.appInfo}>
        <div className={s.qrCode}>
          <img src={qrCode} alt="QR Code" title="qr code" tabIndex="0" />
        </div>

        <div className={s.downloadButtons}>
          <button type="button">
            <img src={googlePlay} alt="Download app at Google play" />
          </button>

          <button type="button">
            <img src={appStore} alt="Download app at App store" />
          </button>
        </div>
      </div>
      */}

      <div className={s.socialMedia}>
        <a
          href="#"
          target="_blank"
          title="facebook"
        >
          <SvgIcon name="facebook" />
          <ToolTip left="50%" top="48px" content="facebook" />
        </a>

        <a
          href="#"
          target="_blank"
          title="twitter"
        >
          <SvgIcon name="twitter" />
          <ToolTip left="50%" top="48px" content="twitter" />
        </a>

        <a
          href="#"
          target="_blank"
          title="instagram"
        >
          <SvgIcon name="instagram" />
          <ToolTip left="50%" top="48px" content="instagram" />
        </a>

        <a
          href="#"
          target="_blank"
          title="linkedin"
        >
          <SvgIcon name="linkedin" />
          <ToolTip left="50%" top="48px" content="linkedin" />
        </a>
      </div>
    </>
  );
};
export default DownloadAppInfo;
