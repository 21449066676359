import { aboutus } from "src/Assets/Images/Images";
import s from "./AboutHeroSection.module.scss";

const AboutHeroSection = () => {
  return (
    <section className={s.heroSection}>
      <section className={s.content}>
        <h2 tabIndex="0">Our Story</h2>

        <p tabIndex="0">
          Launched in 2024, ...
        </p>

        <p tabIndex="0">
          Master Global ...
        </p>
      </section>

      <div className={s.imgHolder}>
        <img src={aboutus} alt="master global" />
      </div>
    </section>
  );
};
export default AboutHeroSection;
