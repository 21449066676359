import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { random } from "src/Functions/helper";

const useUpdateLoadingOnSamePage = ({
  loadingKey,
  cleanFunction,
  delays,
  dependencies = [],
  actionMethod,
}) => {
  const dispatch = useDispatch();
  const timerId = useRef(null);
  let randomDelay = random(delays);

  useEffect(() => {
    function updateLoadingState() {
      dispatch(actionMethod({ key: loadingKey, value: true }));

      timerId.current = setTimeout(() => {
        dispatch(actionMethod({ key: loadingKey, value: false }));
      }, randomDelay);

      randomDelay = random(delays);
    }

    updateLoadingState();

    return () => {
      clearTimeout(timerId.current);
      if (cleanFunction) cleanFunction();
    };
  }, dependencies); // Chỉ kích hoạt lại effect khi dependencies thay đổi
};

export default useUpdateLoadingOnSamePage;
