import { useDispatch, useSelector } from "react-redux";
import { updateGlobalState } from "src/Features/globalSlice";
import SvgIcon from "../../Shared/MiniComponents/SvgIcon";
import DropDownMenu from "./DropDownMenu";
import s from "./SectionsMenu.module.scss";

const SectionsMenu = () => {
  const dispatch = useDispatch();
  const { isSectionsMenuActive } = useSelector((state) => state.global);

  return (
    <>
      <button
        type="button"
        className={s.sectionsMenuButton}
        onClick={() => {
          dispatch(
            updateGlobalState({ key: "isSectionsMenuActive", value: true })
          );
          dispatch(updateGlobalState({ key: "isOverlayActive", value: true }));
        }}
        aria-label="List of sections"
      >
        <SvgIcon name="list" />
      </button>

      <nav
        className={`${s.sectionsMenu} ${isSectionsMenuActive ? s.active : ""}`}
      >
        <DropDownMenu nameMenu="Locks & Latches">
          <ul className={s.dropDownMenu}>
            <li>
              <a href="\#">Locks</a>
            </li>
            <li>
              <a href="\#">Latches</a>
            </li>
          </ul>
        </DropDownMenu>

        <DropDownMenu nameMenu="Hinges & Handles">
          <ul className={s.dropDownMenu}>
            <li>
              <a href="\#">Hinges</a>
            </li>
            <li>
              <a href="\#">Handles</a>
            </li>
            <li>
              <a href="\#">Door Stop</a>
            </li>
          </ul>
        </DropDownMenu>

        <a href="\#">Drawer Sliders</a>
        <a href="\#">Sealing Strip</a>
        <a href="\#">Height Adjustable Desks</a>
        <a href="\#">Wheels & Castors</a>
      </nav>
    </>
  );
};

export default SectionsMenu;
